<template>
<div id="gameserver">
  <help-game-server  v-if="tabIndex === 0" class="markdown-body" />
  <help-game-server-editor v-else class="markdown-body" />
  <b-tabs v-model="tabIndex" position="is-centered" type="is-boxed" size="is-medium">
    <b-tab-item label="游戏服列表">
      <b-table
        :data="servervos"
        detailed
        detail-key="vid">
        <b-table-column #default="props" field="status" label="状态" width="100">
          <b-tag :type="statusColor(props.row.status)"> {{ statusText(props.row.status) }}</b-tag>
        </b-table-column>
        <b-table-column #default="props" field="vid" label="VID" width="40" sortable numeric>
          {{ props.row.vid }}
        </b-table-column>
        <b-table-column #default="props" field="name" label="name" width="120">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column #default="props" field="ver" label="ver" width="80">
          {{ props.row.ver }}
        </b-table-column>
        <b-table-column #default="props" field="copy" label="URL" width="100" centered>
          <b-tooltip
            :label="`复制URL到剪贴板: ${getConfigUrl(props.row)}`">
            <b-button
              :id="`copy-url-${props.row.vid}`"
              icon-right="link-plus"
              type="is-text"
              :data-clipboard-text="getConfigUrl(props.row)"
              @click="onClipboardClick(`copy-url-${props.row.vid}`)" />
          </b-tooltip>
        </b-table-column>
        <b-table-column #default="props" field="createtime" label="创建日期" sortable>
          {{ formatDateTime(props.row.createtime) }}
        </b-table-column>
        <b-table-column #default="props" field="updatetime" label="更新日期" sortable>
          {{ formatDateTime(props.row.updatetime) }}
        </b-table-column>
        <b-table-column #default="props" field="note" label="备注" expanded>
          {{ props.row.note }}
        </b-table-column>
        <b-table-column #default="props" label="操作">
          <div class="buttons">
            <button class="button is-warning" @click="onEditClick(props.row)">修改</button>
            <button :disabled="isDelDisabled(props.row.ver)" class="button is-danger" @click="onDeleteClick(props.row)">删除</button>
          </div>
        </b-table-column>
        <template #detail="props">
          <ul>
            <li v-for="(s, i) in getServervoTip(props.row)" :key="i">{{s.host}}:{{s.port}} ssl:{{s.ssl}}{{s.halt ? '停服:' + s.message : ''}}</li>
          </ul>
        </template>
      </b-table>
    </b-tab-item>
    <b-tab-item label="增加配置">
      <server-editor ref="serverAdd" @edit="onEditSave" />
    </b-tab-item>
    <b-tab-item label="编辑配置" :disabled="!inEdit">
      <server-editor ref="serverEdit" is-edit="true" @edit="onEditSave" />
    </b-tab-item>
  </b-tabs>
</div>
</template>

<script>
import ServerEditor from '@/components/vo/ServerEditor'
import cache from '@/core/cache'
import Clipboard from 'clipboard'
import HelpGameServer from '@/help/vogs.md'
import HelpGameServerEditor from '@/help/vogse.md'

export default {
  components: { ServerEditor, HelpGameServer, HelpGameServerEditor },
  mounted () {
    this.getServers()
  },
  data () {
    return {
      tabIndex: 0,
      servervos: [],
      inEdit: false,
      regional: cache.getR()
    }
  },
  methods: {
    getConfigUrl (row) {
      const configUrlPrefix = '/cf/server/get/'
      const baseUrl = this.mjp.getMJPURI(configUrlPrefix, 'auth', this.regional)
      const params = ['r=' + this.regional, 'ver=' + row.ver]
      const url = `${baseUrl}${configUrlPrefix}?${params.join('&')}`
      return url
    },
    statusColor (status) {
      return status === 1 ? 'is-success' : 'is-danger'
    },
    statusText (status) {
      return status === 1 ? '启用' : '禁用'
    },
    onClipboardClick (btnid) {
      const c = new Clipboard(`#${btnid}`)
      c.on('success', e => {
        this.hub.alert(`已经复制 ${e.text} 到剪贴板。`, 1.5, 'is-success')
      })
      c.on('error', e => {
        this.hub.alert('复制失败啦！您自己解决吧。', 1.5, 'is-danger')
      })
    },
    /**
     * 根据服务器vo信息返回tooltip显示
     */
    getServervoTip (vo) {
      let servers = vo.servers
      // 兼容旧版本的配置。旧版本没有 servers 字段
      if (!servers) {
        servers = [vo]
      }
      return servers
      // for (let s of servers) {
      //   tipStr.push(`${s.host}:${s.port} ssl:${s.ssl} 停服:${s.halt}`)
      // }
      // return tipStr.join('<br>')
    },
    isDelDisabled (ver) {
      const v = Number.parseInt(ver)
      if (Number.isNaN(v)) return true
      return v === 0
    },
    onEditClick (vo) {
      this.$refs.serverEdit.setValues(vo)
      this.tabIndex = 2
      this.inEdit = true
    },
    async onDeleteClick (vo) {
      const msg = `是否要删除服务器配置 ${vo.ver} ？`
      this.$buefy.dialog.confirm({
        title: '删除服务器配置',
        message: msg,
        type: 'is-danger',
        onConfirm: async () => {
          try {
            await this.mjp.post('/cf/server/del/', null, { ver: vo.ver })
            this.hub.alert(`删除服务器配置 ${vo.ver} 成功！`, 5)
            this.getServers()
          } catch (error) {
            console.error(error)
          }
        }
      })
    },
    async onEditSave (vo) {
      // 增加和编辑使用同一条协议
      try {
        const url = this.tabIndex === 1 ? '/cf/server/add/' : '/cf/server/set/'
        await this.mjp.post(url, vo.value, { ver: vo.ver })
        this.tabIndex = 0
      } catch (error) {
        console.error(error)
      }
    },
    async getServers () {
      try {
        const data = await this.mjp.get('/cf/vo/get/all/', { votype: 304, mergevo: 1 })
        this.servervos = data.vos
      } catch (error) {
        console.error(error)
      }
    }
  },
  watch: {
    tabIndex (to, from) {
      if (from === 2) {
        this.inEdit = false
        this.$refs.serverEdit.clearValues()
      }
      if (from === 1) {
        this.$refs.serverAdd.clearValues()
      }
      if (to === 0) {
        this.getServers()
      }
    }
  }
}
</script>
