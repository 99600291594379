<template>
  <div id="servereditor">
    <b-field :disabled="isEdit" horizontal label="版本号(x.y.z)">
      <b-input name="ver" v-model.trim="ver"></b-input>
    </b-field>
    <b-field horizontal label="状态">
      <b-switch v-model="statusBool">{{statusBool ? '启用' : '禁用'}}</b-switch>
    </b-field>
    <b-field horizontal label="备注">
      <b-input name="note" v-model.trim="note" placeholder="可留空"></b-input>
    </b-field>
    <b-field horizontal position="is-cenerted">
      <button class="button is-success" @click="onAddClick">增加主机</button>
      <button class="button is-danger" :disabled="!serverSelected" @click="onRemoveClick">删除主机</button>
      <button class="button is-warning" :disabled="!canSave" @click="onSaveClick">保存全部</button>
    </b-field>
    <b-field disabled v-if="servers.length>0" horizontal label="所有主机停服">
      <b-switch v-model="haltAll" @input="onHaltAll">所有主机停服 ({{haltAll}})</b-switch>
      <b-input v-if="haltAll" v-model.trim="haltMsg" placeholder="输入所有主机的停服信息" expanded @input="onHaltMsg"></b-input>
    </b-field>
    <b-message v-if="servers.length>0" type="is-danger">
      主机可以设置多个，但客户端一次只会得到一个，用于负载均衡。
    </b-message>
    <b-field v-if="serverSelected" horizontal label="单个主机设置">
      <b-input v-model.trim="serverSelected.host" placeholder="输入主机名" expanded></b-input>
      <b-input v-model.number="serverSelected.port" placeholder="输入端口号"></b-input>
      <b-switch v-model="serverSelected.ssl">SSL ({{serverSelected.ssl}})</b-switch>
    </b-field>
    <b-field v-if="serverSelected" horizontal label="单个主机停服">
      <b-switch v-model="serverSelected.halt">选中主机停服 ({{serverSelected.halt}})</b-switch>
      <b-input v-if="serverSelected.halt" v-model.trim="serverSelected.msg" placeholder="输入单个主机的停服信息" expanded></b-input>
    </b-field>
    <b-field>
      <b-table :data="servers" :selected.sync="serverSelected" focusable>
        <b-table-column #default="props" field="host" label="host" width="300">
          {{ props.row.host }}
        </b-table-column>
        <b-table-column #default="props" field="port" label="port" width="80" numeric>
          {{ props.row.port }}
        </b-table-column>
        <b-table-column #default="props" field="ssl" label="ssl" width="40">
          {{ props.row.ssl }}
        </b-table-column>
        <b-table-column #default="props" field="halt" label="halt" width="40">
          {{ props.row.halt }}
        </b-table-column>
        <b-table-column #default="props" field="msg" label="msg">
          {{ props.row.msg }}
        </b-table-column>
      </b-table>
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'servereditor',
  computed: {
    canSave () {
      // ver 的值可以是 0 或者 x.y.z
      const v = Number.parseInt(this.ver)
      let verTestPass = false
      if (!Number.isNaN(v)) {
        verTestPass = v === 0
      }
      verTestPass = verTestPass || /^\d+\.\d+\.\d+$/.test(this.ver)
      const s = verTestPass && this.servers && this.servers.length > 0
      // 编辑状态下还需要检验渠道ID
      if (this.isEdit) {
        return s && this.vid
      }
      return s
    }
  },
  // 编辑管理员则值为 true， 增加管理员则值为 false
  props: ['isEdit'],
  data () {
    return {
      vid: null,
      ver: null,
      statusBool: true,
      note: null,
      servers: [],
      serverSelected: null,
      haltAll: false,
      haltMsg: null
    }
  },
  methods: {
    setValues (vo) {
      this.vid = vo.vid
      this.ver = vo.ver
      this.statusBool = vo.status === 1
      this.note = vo.note
      this.servers = vo.servers
      // 兼容旧版本的服务器配置，旧版本的配置仅支持一个服务器
      if (!this.servers) {
        this.servers = [{ host: vo.host, port: vo.port, ssl: vo.ssl, halt: false, msg: '' }]
      }
      this.serverSelected = null
    },
    clearValues () {
      this.vid = null
      this.ver = null
      this.servers = []
      this.statusBool = true
      this.note = null
      this.serverSelected = null
      this.haltAll = false
      this.haltMsg = null
    },
    onHaltAll () {
      // 当设置全部主机的时候取消选择
      this.serverSelected = null
      if (this.haltAll) {
        this.haltMsg = '服务器正在维护中，预计 5 分钟后开服。'
      } else {
        this.haltMsg = null
      }
      for (let i = 0; i < this.servers.length; i++) {
        const s = this.servers[i]
        s.halt = this.haltAll
        s.msg = this.haltMsg
      }
    },
    onHaltMsg () {
      if (!this.haltAll) return
      if (!this.haltMsg) return
      for (let i = 0; i < this.servers.length; i++) {
        const s = this.servers[i]
        s.msg = this.haltMsg
      }
    },
    onAddClick () {
      this.servers.push({ host: '请填写主机名', port: 0, ssl: true, halt: false, msg: '' })
    },
    onRemoveClick () {
      const index = this.servers.indexOf(this.serverSelected)
      this.servers.splice(index, 1)
      this.serverSelected = null
    },
    onSaveClick () {
      // 需要检测 server 中的值
      for (let i = 0; i < this.servers.length; i++) {
        const s = this.servers[i]
        if (s.port <= 0 || !s.host) {
          this.hub.alert(`请检查服务器配置${i}。`, 4000)
          return
        }
      }
      const vo = {
        vid: this.vid,
        ver: this.ver,
        value: {
          ver: this.ver,
          servers: this.servers,
          // status 和 note 包含在 value 里面并没有写错，MJP 中做了特殊处理
          status: this.statusBool ? 1 : 5,
          note: this.note
        }
      }
      this.$emit('edit', vo)
    }
  }
}
</script>
